import {
  confirmSignIn,
  fetchAuthSession,
  signIn,
  signOut,
  updatePassword,
} from 'aws-amplify/auth';
import axios from 'axios';

import { configureAmplify, POLICY_BASE_API } from '@pumpkincare/config';
import {
  buildAuthHeaders,
  responseDataBody,
  transformAttachment,
} from '@pumpkincare/shared';

import { transformPayment } from './user-utils';

export function logIn(email, password) {
  configureAmplify();

  return signIn({ username: email.toLowerCase(), password }).catch(error => {
    if (error.code === 'UserNotFoundException') {
      return signIn({ username: email, password });
    } else {
      throw error;
    }
  });
}

export function customSignInToCognito(email, access_token) {
  configureAmplify();

  return signIn({
    username: email,
    options: { authFlowType: 'CUSTOM_WITHOUT_SRP' },
  }).then(() => {
    return confirmSignIn({ challengeResponse: `vet_survey_token::${access_token}` });
  });
}

export function logOut() {
  return signOut();
}

export function putUserAch(body) {
  return fetchAuthSession().then(authSession =>
    axios
      .put(`${POLICY_BASE_API}/users/ach`, body, buildAuthHeaders(authSession))
      .then(responseDataBody)
  );
}

export function deleteUserAch() {
  return fetchAuthSession().then(authSession =>
    axios.delete(`${POLICY_BASE_API}/users/ach`, buildAuthHeaders(authSession))
  );
}

export function getUserPaymentMethod() {
  return fetchAuthSession().then(authSession =>
    axios
      .get(`${POLICY_BASE_API}/users/payment_method`, buildAuthHeaders(authSession))
      .then(responseDataBody)
      .then(transformPayment)
  );
}

export function putUserPaymentMethod(stripeToken) {
  return fetchAuthSession().then(authSession =>
    axios.put(
      `${POLICY_BASE_API}/users/payment_method`,
      { stripeToken },
      buildAuthHeaders(authSession)
    )
  );
}

export function postChargeLapsedUser(userId) {
  const url = `${POLICY_BASE_API}/users/${userId}/charge-lapsed-invoices`;

  return fetchAuthSession().then(authSession =>
    axios.post(url, {}, buildAuthHeaders(authSession))
  );
}

export function getInvoices() {
  return fetchAuthSession().then(authSession =>
    axios
      .get(`${POLICY_BASE_API}/invoices`, buildAuthHeaders(authSession))
      .then(responseDataBody)
  );
}

export function getNextChanges() {
  return fetchAuthSession().then(authSession =>
    axios
      .get(`${POLICY_BASE_API}/users/next-charges`, buildAuthHeaders(authSession))
      .then(responseDataBody)
  );
}

export function postMedicalHistory(userId, petId, medicalRecord) {
  return fetchAuthSession().then(authSession =>
    axios
      .post(
        `${POLICY_BASE_API}/users/${userId}/pets/${petId}/medical-record`,
        medicalRecord,
        buildAuthHeaders(authSession)
      )
      .then(responseDataBody)
  );
}

export function getUserSelf() {
  return fetchAuthSession().then(authSession => {
    return axios
      .get(`${POLICY_BASE_API}/users/self`, buildAuthHeaders(authSession))
      .then(responseDataBody);
  });
}

export function getUserProfile() {
  return fetchAuthSession().then(authSession =>
    axios
      .get(`${POLICY_BASE_API}/users/profile`, buildAuthHeaders(authSession))
      .then(responseDataBody)
  );
}

export function updateUserPaperless({ is_paperless, terms_version }) {
  return fetchAuthSession().then(authSession =>
    axios
      .patch(
        `${POLICY_BASE_API}/users/paperless`,
        { is_paperless, terms_version },
        buildAuthHeaders(authSession)
      )
      .then(responseDataBody)
  );
}

export function downloadPolicy(policyId) {
  const path = `${POLICY_BASE_API}/policies/policy-url/${policyId}`;

  return fetchAuthSession().then(authSession =>
    axios
      .get(path, buildAuthHeaders(authSession))
      .then(responseDataBody)
      .then(result => result.url)
  );
}

export function downloadUpdatedPolicy(policyId, documentId) {
  const path = `${POLICY_BASE_API}/policies/${policyId}/documents/${documentId}/document-url`;

  return fetchAuthSession().then(authSession =>
    axios
      .get(path, buildAuthHeaders(authSession))
      .then(responseDataBody)
      .then(result => result.url)
  );
}

export function changeEmail(newEmail, userId) {
  return fetchAuthSession().then(authSession => {
    const url = `${POLICY_BASE_API}/users/${userId}/change-email-address`;

    return axios
      .put(url, { email: newEmail }, buildAuthHeaders(authSession))
      .then(response => {
        if (response.status === 204) {
          return response;
        } else {
          throw 'Unknown response from server';
        }
      });
  });
}

export function fetchUserVets(userId) {
  return fetchAuthSession().then(authSession => {
    const url = `${POLICY_BASE_API}/users/${userId}/vets`;

    return axios.get(url, buildAuthHeaders(authSession)).then(responseDataBody);
  });
}

export function postUserVets(userId, vetId, body) {
  return fetchAuthSession().then(authSession => {
    const url = `${POLICY_BASE_API}/users/${userId}/vets/${vetId}`;

    return axios
      .post(url, body, buildAuthHeaders(authSession))
      .then(responseDataBody);
  });
}

export function putUserVets(userId, vetId, body) {
  return fetchAuthSession().then(authSession => {
    const url = `${POLICY_BASE_API}/users/${userId}/vets/${vetId}`;

    return axios
      .put(url, body, buildAuthHeaders(authSession))
      .then(responseDataBody);
  });
}

export function uploadFile(file, rawFile, type) {
  return obtainSignedUrlForFiles([rawFile], type)
    .then(signedUrls => {
      const signedUrlParams = signedUrls[file.name];

      file.key = signedUrlParams.fields.key;

      return uploadAttachments([rawFile], signedUrls);
    })
    .then(() => {
      return file;
    });
}

export function obtainSignedUrlForFiles(files, type) {
  return fetchAuthSession().then(authSession => {
    const fileNames = files.map(file => file.name);

    return axios
      .post(
        `${POLICY_BASE_API}/users/signed-url`,
        {
          files: fileNames,
          file_type: type,
        },
        buildAuthHeaders(authSession)
      )
      .then(response => response.data);
  });
}

export function postSignedUrlForPetPhoto(fileName) {
  return fetchAuthSession().then(authSession => {
    return axios
      .post(
        `${POLICY_BASE_API}/users/signed-url/pet-photo`,
        { file: fileName },
        buildAuthHeaders(authSession)
      )
      .then(responseDataBody);
  });
}

export function uploadAttachments(files, signedUrls) {
  const uploadPromises = files.map(file => {
    const [postUrl, formData] = transformAttachment(file, signedUrls);

    return axios.post(postUrl, formData);
  });

  return Promise.all(uploadPromises);
}

export function downloadPlanDoc(petPlanId) {
  const path = `${POLICY_BASE_API}/pet-plans/${petPlanId}/doc`;

  return fetchAuthSession().then(authSession =>
    axios.get(path, buildAuthHeaders(authSession)).then(response => {
      return response.data.body.url;
    })
  );
}

export function downloadWellnessDoc(wellnessId) {
  const path = `${POLICY_BASE_API}/wellness/${wellnessId}/doc`;

  return fetchAuthSession().then(authSession =>
    axios.get(path, buildAuthHeaders(authSession)).then(response => {
      return response.data.body.url;
    })
  );
}

export function changePassword(oldPassword, newPassword) {
  return fetchAuthSession().then(() => updatePassword({ oldPassword, newPassword }));
}

export function checkUserToken(params) {
  const { token, tokenType = 'password_signup' } = params;
  const url = `${POLICY_BASE_API}/users/check-token?token=${token}&type=${tokenType}`;

  return axios.post(url, {});
}

export function forgotPassword(email) {
  const url = `${POLICY_BASE_API}/users/forgot-password`;

  return axios.post(url, { email });
}

export function postUserVet(vet_id) {
  const url = `${POLICY_BASE_API}/users/vets`;

  return fetchAuthSession().then(authSession => {
    return axios.post(url, { vet_id }, buildAuthHeaders(authSession));
  });
}

export function postCheckEmail(email) {
  const url = `${POLICY_BASE_API}/users/check_email`;

  return axios.post(url, { email }).then(response => {
    const { exists } = responseDataBody(response);

    return { exists };
  });
}

export function getUserBranding(userId) {
  const url = `${POLICY_BASE_API}/users/${userId}/branding`;

  return axios.get(url).then(responseDataBody);
}

export function getUserAddresses() {
  const url = `${POLICY_BASE_API}/users/addresses`;

  return fetchAuthSession().then(authSession => {
    return axios.get(url, buildAuthHeaders(authSession)).then(responseDataBody);
  });
}

export function putUserBillingAddress(address) {
  const { country, state, city, street_1, street_2, zipcode } = address;
  const url = `${POLICY_BASE_API}/users/addresses/billing`;

  return fetchAuthSession().then(authSession => {
    return axios.put(
      url,
      {
        country,
        state,
        city,
        street_1,
        street_2,
        zipcode,
      },

      buildAuthHeaders(authSession)
    );
  });
}

export function putUserPassword(userId, password) {
  return fetchAuthSession().then(authSession => {
    return axios.put(
      `${POLICY_BASE_API}/users/${userId}`,
      {
        password,
      },

      buildAuthHeaders(authSession)
    );
  });
}

export function getUserPetPhotos() {
  return fetchAuthSession().then(authSession =>
    axios
      .get(`${POLICY_BASE_API}/users/pets/pet-photos`, buildAuthHeaders(authSession))
      .then(responseDataBody)
  );
}

export function getUserPetPhotosById(petId) {
  return fetchAuthSession().then(authSession =>
    axios
      .get(
        `${POLICY_BASE_API}/users/pets/${petId}/pet-photo`,
        buildAuthHeaders(authSession)
      )
      .then(responseDataBody)
  );
}

export function postPetPhoto(petId, file_key, source_file_name) {
  return fetchAuthSession().then(authSession =>
    axios
      .post(
        `${POLICY_BASE_API}/users/pets/${petId}/pet-photo`,
        { file_key, source_file_name },
        buildAuthHeaders(authSession)
      )
      .then(responseDataBody)
  );
}

export function deleteUserPetPhotoById(petId) {
  return fetchAuthSession().then(authSession => {
    return axios
      .delete(
        `${POLICY_BASE_API}/users/pets/${petId}/pet-photo`,
        buildAuthHeaders(authSession)
      )
      .then(responseDataBody);
  });
}

export function getSubDiagnoses() {
  return fetchAuthSession().then(authSession =>
    axios
      .get(
        `${POLICY_BASE_API}/diagnosis/sub-diagnosis?range=${encodeURIComponent(
          '[0, 999]'
        )}`,
        buildAuthHeaders(authSession)
      )
      .then(responseDataBody)
  );
}

export function getPetIncidentHistory(petId) {
  return fetchAuthSession().then(authSession =>
    axios
      .get(
        `${POLICY_BASE_API}/users/pets/${petId}/incident-history`,
        buildAuthHeaders(authSession)
      )
      .then(responseDataBody)
  );
}

export function getSwifterToken(petId) {
  const url = `${POLICY_BASE_API}/users/swifter_token`;

  return fetchAuthSession().then(authSession =>
    axios
      .post(url, { pet_id: petId }, buildAuthHeaders(authSession))
      .then(response => response.data)
  );
}

export const UserService = {
  changeEmail,
  fetchUserVets,
  postUserVets,
  putUserVets,
  uploadAttachments,
  obtainSignedUrlForFiles,
  checkUserToken,
  forgotPassword,
};
