import classNames from 'classnames';
import PropTypes from 'prop-types';

import { formatCurrency } from '@pumpkincare/shared';
import {
  Body2,
  ButtonStyles,
  CheckCircle,
  LegalBody,
  ProgressBar,
} from '@pumpkincare/shared/ui';

import { downloadPolicy, downloadUpdatedPolicy } from '../../user-service';
import CoverageDates from '../coverage-dates';
import CoverageDocuments from '../coverage-documents';

import styles from './pet-policy-overview.css';

const PILLS = {
  Active: styles.active,
  'Renewal Declined': styles.renewalDeclined,
  Cancelled: styles.cancelled,
  'Billing Lapsed': styles.billingLapsed,
  Pending: styles.pending,
};

function PetPolicyUpsell(isNull) {
  /*
  WELLNESS->INSURANCE UPSELL
  remove `isNull` conditional and prop, maybe even just inline the jsx instead of having a component
 */
  if (isNull) return null;

  return (
    <div className={styles.upsell}>
      <Body2 isBold style={{ marginBottom: '8px' }}>
        Pumpkin Pet Insurance Upsell
      </Body2>
      <LegalBody>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sed elit
        eget sapien volutpat scelerisque.
      </LegalBody>

      <div className={styles.callout}>
        <button className={ButtonStyles.secondary}>Enroll Now</button>
      </div>
    </div>
  );
}

function PetPolicyOverview({
  classes,
  price,
  utilization,
  documents,
  dateConfig,
  hasInsurance,
  petStatus,
  reimbursementPercentage,
}) {
  const { deductible, annualLimit } = utilization.reduce((result, curr) => {
    const key = curr.entitlement === 'Deductible' ? 'deductible' : 'annualLimit';
    const total = curr.available + curr.pending + curr.utilized;
    const isMet = total === curr.utilized;

    result[key] = {
      ...curr,
      total,
      isMet,
    };
    return result;
  }, {});

  function handleDocumentClick(document) {
    const download = document.isChange
      ? () =>
          downloadUpdatedPolicy(
            document.policy_id,
            document.document_id || document.id
          )
      : () => downloadPolicy(document.policy_id);

    download().then(url => {
      window.open(url);
    });
  }

  return (
    <div className={classNames(styles.root, classes.root)}>
      <div className={styles.overview}>
        <div className={styles.header}>
          <div className={styles.titleWrapper}>
            <h5>Pumpkin Pet Insurance</h5>
            <LegalBody data-testid={'statusPill'} className={PILLS[petStatus]}>
              {petStatus}
            </LegalBody>
          </div>
          {price.cost ? (
            <LegalBody>
              <b>{formatCurrency(price.cost)}</b> / {price.frequency}
            </LegalBody>
          ) : null}
        </div>

        {hasInsurance ? (
          <>
            <div className={styles.utilization}>
              <Body2 isBold style={{ marginBottom: '8px' }}>
                Usage
              </Body2>

              {!deductible.isMet ? (
                <div className={styles.utilProgress}>
                  <div className={styles.utilFlex}>
                    <LegalBody>
                      <b>
                        {formatCurrency(deductible.total, {
                          hideZero: true,
                          areCents: true,
                        })}
                      </b>{' '}
                      Annual Deductible
                    </LegalBody>

                    <LegalBody>
                      Paid{' '}
                      <b>
                        {formatCurrency(deductible.utilized, {
                          areCents: true,
                        })}
                      </b>
                    </LegalBody>
                  </div>

                  <ProgressBar
                    value={(deductible.utilized / deductible.total) * 100}
                    classes={{
                      bar: styles.bar,
                    }}
                  />
                </div>
              ) : (
                <LegalBody className={styles.utilBar}>
                  <CheckCircle color={'var(--bluesCluesBlue)'} />
                  <b>
                    {formatCurrency(deductible.total, {
                      hideZero: true,
                      areCents: true,
                    })}
                  </b>{' '}
                  Deductible Paid
                </LegalBody>
              )}

              {deductible.isMet && !annualLimit.isMet ? (
                <div className={styles.utilProgress}>
                  <div className={styles.utilFlex}>
                    <LegalBody>
                      <b>
                        {annualLimit.available === -1
                          ? 'Unlimited'
                          : formatCurrency(annualLimit.total, {
                              hideZero: true,
                              areCents: true,
                            })}
                      </b>{' '}
                      Annual&nbsp;Coverage&nbsp;Limit
                    </LegalBody>

                    <LegalBody>
                      Reimbursed{' '}
                      <b>
                        {formatCurrency(annualLimit.utilized, {
                          areCents: true,
                        })}
                      </b>
                    </LegalBody>
                  </div>

                  <ProgressBar
                    value={
                      annualLimit.available === -1
                        ? 0
                        : (annualLimit.utilized / annualLimit.total) * 100
                    }
                    classes={{
                      bar: styles.bar,
                    }}
                  />
                </div>
              ) : (
                <LegalBody className={styles.utilBar}>
                  {annualLimit.isMet ? (
                    <CheckCircle color={'var(--bluesCluesBlue)'} />
                  ) : null}
                  <b>
                    {annualLimit.total === -1
                      ? 'Unlimited'
                      : formatCurrency(annualLimit.total, {
                          hideZero: true,
                          areCents: true,
                        })}
                  </b>{' '}
                  Annual Coverage Limit {annualLimit.isMet ? 'Filled' : ''}
                </LegalBody>
              )}

              <LegalBody className={styles.utilBar}>
                <b>{reimbursementPercentage}</b> Reimbursement Rate
              </LegalBody>
            </div>

            {documents.length ? (
              <CoverageDocuments
                documents={documents}
                handleDocumentClick={handleDocumentClick}
              />
            ) : null}
          </>
        ) : (
          <PetPolicyUpsell isNull />
        )}
      </div>

      <div className={styles.datesAside}>
        {dateConfig.dates.length ? (
          <CoverageDates
            dates={dateConfig.dates}
            canContact={dateConfig.canContact}
          />
        ) : null}
      </div>
    </div>
  );
}

PetPolicyOverview.defaultProps = {
  classes: {},
  price: {},
  documents: [],
  petStatus: 'Active',
};

PetPolicyOverview.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),

  price: PropTypes.shape({
    cost: PropTypes.number,
    frequency: PropTypes.oneOf(['month', 'year']),
  }),

  utilization: PropTypes.arrayOf(
    PropTypes.shape({
      available: PropTypes.number.isRequired,
      pending: PropTypes.number.isRequired,
      utilized: PropTypes.number.isRequired,
      entitlement: PropTypes.oneOf(['Annual Limit', 'Deductible']).isRequired,
    })
  ).isRequired,

  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      policy_id: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
      processingText: PropTypes.string.isRequired,
    })
  ),

  dateConfig: PropTypes.shape({
    dates: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
      })
    ).isRequired,
    canContact: PropTypes.bool.isRequired,
  }).isRequired,

  hasInsurance: PropTypes.bool,

  petStatus: PropTypes.string,

  reimbursementPercentage: PropTypes.string,
};

export default PetPolicyOverview;
